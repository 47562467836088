<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
    export default {
        name:'app',
        provide(){
            return {
                reload:this.reload
            }
        },
        data(){
            return {
                isRouterAlive:true
            }
        },
        methods:{
            reload(){
                this.isRouterAlive = false
                this.$nextTick(()=>{
                    this.isRouterAlive = true
                })
            }
        }
    }
</script>
<style lang="scss">
  @import '@/assets/css/base';
  @import '@/assets/css/common.scss';
  #app {
    font-family: PingFangSC-Regular, PingFang SC;
    color: #2c3e50;
  }
</style>
