import Vue from "vue";
import VueRouter from "vue-router";
import {toLogin} from "@/assets/js/utils";

Vue.use(VueRouter);
const __import__ = file => () => import(`@/pages/${file}.vue`);
const routes = [
    {
        path: "/CreateStore",
        name: "CreateStore",
        component: __import__("CreateStore/index")
    },
    {
        path: "/login",
        name: "login",
        component: __import__("login/index")
    },
    {
        path: "/register",
        name: "register",
        component: __import__("register/index")
    },
    {
        path: "/register/success",
        name: "registerSuccess",
        component: __import__("register/success/index")
    },
    {
        path: "/register/agreement/PrivacyPolicy",
        name: "PrivacyPolicy",
        component: __import__("register/agreement/PrivacyPolicy")
    },
    {
        path: "/register/agreement/TermsOfService",
        name: "TermsOfService",
        component: __import__("register/agreement/TermsOfService")
    },
  {
    path: "/register/agreement/SiteConstructionServiceAgreement",
    name: "SiteConstructionServiceAgreement",
    component: __import__("register/agreement/SiteConstructionServiceAgreement")
  },
    {
        path: "/register/enterprise",
        component: __import__("register/enterprise/index"),
        children: [
            {
                path: "/",
                component: __import__("register/enterprise/Step1/index"),
                beforeEnter: (to, from, next) => {
                    if (
                        from.path == "/register" ||
                        from.path == "/register/enterprise/Step2"
                    ) {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            },
            {
                path: "Step2",
                component: __import__("register/enterprise/Step2/index"),
                beforeEnter: (to, from, next) => {
                    if (
                        from.path == "/register/enterprise" ||
                        from.path == "/register/enterprise/Step3"
                    ) {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            },
            {
                path: "Step3",
                component: __import__("register/enterprise/Step3/index"),
                beforeEnter: (to, from, next) => {
                    if (from.path == "/register/enterprise/Step2") {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            }
        ]
    },
    {
        path: "/register/personal",
        component: __import__("register/personal/index"),
        children: [
            {
                path: "/",
                component: __import__("register/personal/Step1/index"),
                beforeEnter: (to, from, next) => {
                    if (
                        from.path == "/register" ||
                        from.path == "/register/personal/Step2"
                    ) {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            },
            {
                path: "Step2",
                component: __import__("register/personal/Step2/index"),
                beforeEnter: (to, from, next) => {
                    if (
                        from.path == "/register/personal" ||
                        from.path == "/register/personal/Step3"
                    ) {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            },
            {
                path: "Step3",
                component: __import__("register/personal/Step3/index"),
                beforeEnter: (to, from, next) => {
                    if (from.path == "/register/personal/Step2") {
                        next();
                    } else {
                        next({path: "/register"});
                    }
                }
            }
        ]
    },
    {
        path: "/resetUser",
        name: "resetUser",
        component: __import__("resetUser/index")
    },
    {
        path: "/",
        name: "main",
        component: __import__("index/index"),
        beforeEnter: (to, from, next) => {
            if(process.env.NODE_ENV === "production"){
                window.location.href = 'https://seller.funpinpin.com/'
            }else{
                next()
            }
        }
    },
    {
        path: "/backstage",
        component: __import__("backstage/index"),
        children: [
            {
                path: "/",
                component: __import__("Home")
            }
        ]
    },
    {
        path: "/storeManagement",
        name: "storeManagement",
        component: __import__("storeManagement/index"),
        children: [
            {
                path: "home",
                name: "home",
                component: __import__("storeManagement/home/index")
            },
            {
                path: "storeList",
                name: "storeList",
                component: __import__("storeManagement/storeList/index")
            },
            {
                path: "agreementList",
                name: "agreementList",
                component: __import__("storeManagement/agreementList/index")
            },
            {
                path: "shopDetail",
                name: "shopDetail",
                component: __import__("storeManagement/shopDetail/index")
            },
            {
                path: "billingDetail",
                name: "billingDetail",
                component: __import__("storeManagement/billingDetail/index")
            },
            {
                path: "choosePackage",
                name: "choosePackage",
                component: __import__("storeManagement/choosePackage/index")
            },
            {
                path: "completePayment",
                name: "completePayment",
                component: __import__("storeManagement/completePayment/index")
            }
        ]
    }
];
const router = new VueRouter({
    mode: "history",
    routes
});

// 解决: Error: Avoided redundant navigation to current location:报错显示是路由重复
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

// 路由守卫，title
router.beforeEach((to, from, next) => {
    let path = to.path;
    console.log(/(^\/$)|(^\/login$)|(^\/register)/.test(path));
    if (/(^\/$)|(^\/login$)|(^\/register)|(^\/resetUser)/.test(path)) {
        next();
    } else if (
        typeof localStorage["token"] !== "undefined" &&
        localStorage["token"] !== ""
    ) {
        next();
    } else {
        toLogin();
    }
});

export default router;
