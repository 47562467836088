import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //登录用户
    userMsg: {},
    ERFrom: {
      // //企业表单
      // name: "", //联系人姓名
      // email: "", //联系人邮箱
      // code: "", //邮箱验证码
      // phoneNo: "", //联系电话
      // enterpriseName: "", //企业名
      // province: "", //省
      // city: "", //市
      // DetailedAddress: "", //详细地址
      // isPast: "", //是否从事过跨境电商业务
      // DailyTurnover: "", //单日营业额
      // CommodityType: "", //商品类型
      // isAdvBertisementBusiness: "", //是否在木瓜进行过广告业务*
      // isAdvBertisementAccount: "", //曾在木瓜使用的广告账户*
      // BusinessLicense: "", //营业执照
      // CorporateIdentityCardTop: "", //法人身份证(人像)
      // CorporateIdentityCardBottom: "", //法人身份证（国徽）
      // BankAccountFlow: "", //银行账户流水
      // Paypal: "", //Paypal账户余额
      // OperationDetailsOrder: "", //运营明细(上传订单明细)
      // OperationDetailsExpress: "", //运营明细(上传发货明细)
    }
  },
  mutations: {
    init_ERFrom(state) {
      state.ERFrom = {};
      sessionStorage.removeItem("fpp-ERFrom");
    },
    upData_ERFrom(state, vel) {
      state.ERFrom = vel;
      sessionStorage.setItem("fpp-ERFrom", JSON.stringify(vel));
    },
    upDataUserMsg(state, vel) {
      try {
        localStorage.setItem("fpp-userMsg", JSON.stringify(vel));
        state.userMsg = vel;
      } catch (error) {}
    }
  },
  actions: {},
  modules: {}
});
