/** @format */
import { Message } from "element-ui";
import router from "@/router/index";
/**
 * message提示框
 * @param {String} text 提示文字
 * @param {String} type 提示类型 1 success 2 error 3 warning
 * @param {Number} duration 显示时间
 * @param {Boolean} showClose 是否显示关闭按钮
 */
const showMsg = (text, type = 1, duration = 2, showClose = false) => {
  duration = duration * 1000;
  if (type === 1) {
    Message({
      message: text,
      type: "success",
      duration: duration,
      showClose
    });
  } else if (type === 2) {
    Message({
      message: text,
      type: "error",
      duration: duration,
      showClose
    });
  } else if (type === 3) {
    Message({
      message: text,
      type: "warning",
      duration: duration,
      showClose
    });
  } else {
    Message({
      message: text,
      type,
      duration: duration,
      showClose
    });
  }
};
/**
 * 跳转登录页
 */
const toLogin = () => {
  router.replace({
    path: "/login"
  });
};
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 * @param {String} msg 请求失败的返回信息
 */
const handleResponseErrors = (status, msg) => {
  if (msg==="") {
    return;
  }
  switch (status) {
    case 401:
      showMsg("登录过期，请重新登录", "error");
      // 清除token
      localStorage.clear();
      toLogin();
      router.push({ name: "Login" });
      break;
    case 404:
      showMsg("网络请求不存在", "error");
      break;
    case 500:
      showMsg("系统错误,请联系管理员", "error");
      break;
    case 1003:
      showMsg("验证码过期", "warning");
      break;
    default:
      showMsg(msg, "error");
  }
};
/**
 * 通过时间戳生成唯一rid
 */
const formatedRid = () => {
  return new Date().getTime();
};
/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
const Throttle = (fn, t) => {
  let last;
  let timer;
  let interval = t || 500;
  return function() {
    let args = arguments;
    let now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        last = now;
        fn.apply(this, args);
      }, interval);
    } else {
      last = now;
      fn.apply(this, args);
    }
  };
};
/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
const Debounce = (fn, t) => {
  let delay = t || 500;
  let timer;
  // console.log(fn);
  // console.log(typeof fn);
  return function() {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
};
const WwLogin = () => {
  !(function(a, b, c) {
    function d(c) {
      var d = b.createElement("iframe"),
        e =
          "https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=" +
          c.appid +
          "&agentid=" +
          c.agentid +
          "&redirect_uri=" +
          c.redirect_uri +
          "&state=" +
          c.state +
          "&login_type=jssdk";
      (e += c.style ? "&style=" + c.style : ""),
        (e += c.href ? "&href=" + c.href : ""),
        (d.src = e),
        (d.frameBorder = "0"),
        (d.allowTransparency = "true"),
        (d.scrolling = "no"),
        (d.width = "300px"),
        (d.height = "400px");
      var f = b.getElementById(c.id);
      (f.innerHTML = ""),
        f.appendChild(d),
        (d.onload = function() {
          d.contentWindow.postMessage &&
            a.addEventListener &&
            (a.addEventListener("message", function(b) {
              b.data &&
                b.origin.indexOf("work.weixin.qq.com") > -1 &&
                (a.location.href = b.data);
            }),
            d.contentWindow.postMessage("ask_usePostMessage", "*"));
        });
    }
    a.WwLogin = d;
  })(window, document);
};
export {
  toLogin,
  showMsg,
  handleResponseErrors,
  formatedRid,
  Throttle,
  Debounce,
  WwLogin
};
