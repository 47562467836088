import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/css/element.scss';
import VueLazyload from "vue-lazyload";
import {
  Button,
  Upload,
  Icon,
  Message,
  Checkbox,
  Dialog,
  Select,
  Option,
  Input,
  Autocomplete,
  Table,
  TableColumn,
  Pagination,
  Tooltip,
  RadioButton,
  RadioGroup,
  Radio,
  Popover,
  Loading,
  Badge

} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(Button)
    .use(Checkbox)
    .use(Upload)
    .use(Icon)
    .use(Dialog)
    .use(Select)
    .use(Option)
    .use(Input)
    .use(Autocomplete)
    .use(Table)
    .use(TableColumn)
    .use(Pagination)
    .use(Tooltip)
    .use(RadioButton)
    .use(RadioGroup)
    .use(Radio)
    .use(Popover)
    .use(Loading)
    .use(Badge);
Vue.prototype.$message = Message;
Vue.config.productionTip = false;
Vue.use(VueLazyload);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
